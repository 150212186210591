import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import '../styles/learn-more.scss'
import RightArrow from '../images/home/right-arrow.svg'

type Props = {
	imageSrc: string
	imageAlt: string
	header: string
	text: string
	link: string
	className?: string
}

const ArticleBox = (props: Props) => {
	return (
		<div
			className={`${props.className ? props.className : ''} mb-6 flex flex-col`}
		>
			<Link to={props.link} title={props.header}>
				<img
					src={props.imageSrc}
					alt={props.imageAlt}
					className='w-full h-full cursor-pointer aspect-[376/211]'
				/>
			</Link>
			<div className='grow flex flex-col md:mt-6 mt-3'>
				<h2 className='font-poppins text-subhead font-medium'>
					<Link to={props.link}>{props.header}</Link>
				</h2>
				<div className='md:my-4 my-2 font-merriweather text-small grow'>
					{`${props.text.substring(0, 191)}...`}
				</div>
				<Link
					to={props.link}
					className='hover-link font-poppins text-navyBlue text-small leading-8'
				>
					<div>Devamını Oku</div>
					<div className='arrow-wrapper flex items-center flex-1'>
						<img src={RightArrow} alt='' className='arrow' />
					</div>
				</Link>
			</div>
		</div>
	)
}

export default ArticleBox

import React from 'react'

type Props = {
	children: React.ReactNode
	title: string
	subtitle: string
	articleCount: number
}
const ArticleFeed = (props: Props) => {
	return (
		<div
			className={`${
				props.articleCount > 0 ? '' : 'hidden'
			} font-merriweather lg:mb-40 mb-20 article-feed`}
		>
			<h2 className='text-header'>{props.title}</h2>
			<div className='text-small text-grey mt-2 mb-10'>{props.subtitle}</div>
			{props.children}
		</div>
	)
}

export default ArticleFeed

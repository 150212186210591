import React from 'react'
import Phone from '../images/contact-box/phone.svg'
import Mobile from '../images/contact-box/mobile.svg'
import '../styles/contact-box.scss'

const ContactBox = () => {
	return (
		<div className='bg-cream p-12 lg:mb-40 mb-20 flex items-center justify-center font-poppins connection-cta relative'>
			<div>
				<div className='font-medium text-subhead text-black mb-4 text-center'>
					Hizmetlerimiz hakkında detaylı bilgi almak için bizi arayabilirsiniz:
				</div>
				<div className='md:flex justify-center text-[1.25rem] leading-8 text-medium text-navyBlue'>
					<a
						href='tel:0262 303 43 65'
						className='bg-white py-1.5 px-4 md:mr-6 md:mb-0 mb-6 flex items-center justify-center'
					>
						<img src={Phone} alt='' className='mr-2.5' />
						<div>0262 303 43 65</div>
					</a>
					<a
						href='tel:0530 066 3333'
						className='bg-white py-1.5 px-4 flex items-center justify-center'
					>
						<img src={Mobile} alt='' className='mr-2.5' />
						<div>0530 066 3333</div>
					</a>
				</div>
			</div>
		</div>
	)
}

export default ContactBox

import React from 'react'
import Layout from '../templates/layout'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import ContactBox from '../components/ContactBox'
import Hero from '../components/Hero'
import ArticleFeed from '../components/ArticleFeed'
import ArticleBox from '../components/ArticleBox'
import '../styles/categories.scss'

type Props = {
	data: {
		prismicCategories: {
			type: string
			uid: string
			data: {
				is_in_homepage: boolean
				meta_description: string
				meta_title: string
				name: string
				sort_order: number
				icon: {
					url: string
				}
				content: {
					html: string
				}
			}
		}
		allPrismicBlog: {
			nodes: {
				uid: string
				data: {
					meta_description: string
					meta_title: string
					title: {
						text: string
					}
					content: {
						text: string
					}
					image: {
						url: string
						alt: string
					}
					category: {
						slug: string
					}
				}
			}[]
		}
	}
}
const ActivityFieldsDetail = (props: Props) => {
	const activityField = props.data.prismicCategories.data
	const articles = props.data.allPrismicBlog.nodes

	return (
		<Layout border={true}>
			<SEO
				title={activityField.meta_title}
				description={activityField.meta_description}
				path={`/${props.data.prismicCategories.uid}`}
			/>
			<div id='categories-container'>
				<Hero
					header={activityField.name}
					description='Uzman ekibimiz ile tüm hukuki ihtiyaçlarınız için yanınızdayız.
							Akla ve bilgiye dayanan yöntemlerle müvekkillerimize en güncel
							hukuki çözümleri sunuyoruz.'
					links={[
						<Link to='/' className='mr-6 text-small text-grey' key='home-link'>
							Ana Sayfa
						</Link>,
						<Link
							to='/faaliyet-alanlari'
							className='font-medium text-small text-grey'
							key='activity-fields-link'
						>
							Faaliyet Alanları
						</Link>,
					]}
					rightElement={
						<img
							src={activityField.icon.url}
							alt=''
							className='lg:!block !hidden category-image lg:w-[18.375rem]'
						/>
					}
				/>

				<div className='container mb-6'>
					<div className='lg:w-8/12 mx-auto font-merriweather leading-[34px] lg:mb-40 mb-20'>
						<div
							className='html-text'
							dangerouslySetInnerHTML={{ __html: activityField.content.html }}
						/>
					</div>
					<ArticleFeed
						articleCount={articles.length}
						title={`${activityField.name} Makaleleri`}
						subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
					>
						<div className='md:flex md:gap-10'>
							{articles.map((article, a) => {
								return (
									<ArticleBox
										key={`${article.data.title}-${a.toString()}`}
										link={`/${article.data.category.slug}/${article.uid}`}
										className='basis-1/3'
										header={article.data.title.text}
										imageSrc={article.data.image.url}
										imageAlt={article.data.image.alt}
										text='Adli Sicil Kaydı ve Arşiv Kaydı Nasıl Silinir? Adli sicil kaydı halk arasında sabıka kaydı olarak bilinmektedir.
											 Bir suçtan ceza alan ve cezası kesinleşmiş olan kişilerin cezaları adli sicile ...'
									/>
								)
							})}
						</div>
					</ArticleFeed>
					<ContactBox />
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query ActivityField($uid: String) {
		prismicCategories(uid: { eq: $uid }) {
			type
			uid
			data {
				is_in_homepage
				meta_description
				meta_title
				name
				sort_order
				icon {
					url
				}
				content {
					html
				}
			}
		}
		allPrismicBlog(
			filter: { data: { category: { slug: { eq: $uid } } } }
			limit: 3
		) {
			nodes {
				uid
				data {
					meta_description
					meta_title
					title {
						text
					}
					content {
						text
					}
					category {
						slug
					}
					image {
						url
					}
				}
			}
		}
	}
`

export default ActivityFieldsDetail
